import { Injectable } from '@angular/core';
import { AwareHttpRequest, AwareHttpRequestModule, AwareHttpService } from '@appbolaget/aware-http';
import { Application } from '@appbolaget/aware-model';

@Injectable()
export class AppsService {
    private _activeApp: Application;

    get activeApp(): Application {
        return this._activeApp;
    }

    constructor(private api: AwareHttpService) {}

    setActiveApplication(app: Application) {
        this._activeApp = app;
    }

    getApplicationByAlias(alias: string): AwareHttpRequest {
        return this.api.show(`apps`, alias).module(AwareHttpRequestModule.Broadcast).toModel(Application);
    }
}
